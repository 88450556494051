import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2c186a34&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalBase: require('/usr/src/app/components/auth-modal/modal-base.vue').default,NewTermsModal: require('/usr/src/app/components/auth-modal/new-terms-modal.vue').default,LnModal: require('/usr/src/app/components/common-sections/ln-modal.vue').default})
