//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isBefore } from "date-fns";
import terms from "@/mixins/terms";

export default {
  head() {
    return {
      title: "Log In | LegalNature",
    };
  },
  mixins: [terms],
  layout: "account-no-navigation",
  data() {
    return {
      is_auth_modal: true,
      isLogin: false,
      headerLocalFormType: "login",
      query: this.$route.query,
      newTermsModal: false,
    };
  },
  methods: {
    authSuccess() {
      if (this.userAcceptedTerms(this.profile, this.isAuthenticated)) {
        if (
          this.$route.query.callback !== "/account" &&
          this.$route.query.callback
        ) {
          let querystring = this.$route.query.callback;
          this.$router.push(decodeURIComponent(querystring));
        } else this.$router.push("/account");
      } else {
        this.is_auth_modal = false;
        this.newTermsModal = true;
      }
    },
    acceptTermsSuccess() {
      this.$router.push("/account");
      this.newTermsModal = false;
    },
    visibilityChanged(isVisible, entry) {
      this.showFixed = !isVisible;
    },
    showAuthModal() {
      this.is_auth_modal = true;
    },
    hideAuthModal() {
      this.is_auth_modal = false;
      this.$router.push("/");
    },
  },
  computed: {
    isAuthenticated() {
      if (this.profile) return this.profile.registered;
    },
    profile() {
      return this.$store
        .$db()
        .model("profile")
        .query()
        .first();
    },
  },
  mounted() {
    if (process.client) {
      this.$store
        .$db()
        .model("profile")
        .getProfile();
    }
  },
  watch: {
    isAuthenticated(newValue) {
      if (
        newValue === true &&
        this.userAcceptedTerms(this.profile, this.isAuthenticated)
      ) {
        this.$router.push("/account");
      }
    },
  },
};
